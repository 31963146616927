<script setup lang="ts">
import { format } from 'date-fns-tz';
import { Showtime, ShowtimeEvent, TheaterId } from '../hooks/useShowtimes';
import { THEATER_COLORS } from '../colors'

const { date, showtimes } = defineProps<{
  showtimes: Showtime[],
  date: Date[],
}>()

const emit = defineEmits<{
  (e: 'click', showtime: Showtime): void,
  (e: 'update:model-value', date: Date[]): void,
}>()

</script>

<template>
  <v-calendar
    :model-value="date"
    v-on:update:model-value="emit('update:model-value', $event)"
    :events="showtimes"
    hide-week-number
    hide-header
  >
    <template #event="{ event, day }">
      <v-chip
        v-if="!(day as any)?.isHidden"
        :class="['mb-2', event.soldOut && 'text-decoration-line-through']"
        tag="div"
        :color="THEATER_COLORS[(event.theater as any)?.id as TheaterId]"
        density="compact"
        @click="() => emit('click', event as any as ShowtimeEvent)"
      >
        {{
          format((event as any as ShowtimeEvent).start, 'HH:mm', {
            timeZone: 'Europe/Stockholm'
          })
        }}
        {{ (event as any as ShowtimeEvent).movie.title }}
      </v-chip>
    </template>
  </v-calendar>
</template>
