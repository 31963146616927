import { createApp } from 'vue'
import App from './App.vue'

import * as Sentry from "@sentry/vue";

// @ts-expect-error why does this complain?
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VCalendar } from 'vuetify/labs/VCalendar'
import { sv } from 'vuetify/locale'

const app = createApp(App)

console.log("MODE", import.meta.env.MODE)

Sentry.init({
  app,
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  // trackComponents: true,
  // hooks: ["activate", "mount", "update"],
  integrations: [
    Sentry.browserTracingIntegration({
      finalTimeout: 10000,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://filmkalender.work", "http://172.23.47.46", "/^\//"],
  enabled: import.meta.env.MODE === "production",
});

const vuetify = createVuetify({
  components: {
    VCalendar,
  },
  locale: {
    locale: 'sv',
    messages: {
      sv
    }
  }
})

app.use(vuetify).mount('#app')
